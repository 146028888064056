window.addEventListener("DOMContentLoaded", () => {
    const cards = document.querySelectorAll('.ticket-base-info');
    const cardContainers = document.querySelectorAll('.merlin-ticket-animation-enabled');

    window.addEventListener('scroll', () => {
        cards.forEach((card, index) => {
            const rect = card.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            if (rect.top < windowHeight && rect.bottom > 0) {
                if (cardContainers[index] && cardContainers[index].classList) {
                    cardContainers[index].classList.add('in-view'); // Scale up when in view
                }
            } else {
                if (cardContainers[index] && cardContainers[index].classList) {
                    cardContainers[index].classList.remove('in-view'); // Scale back when out of view
                }
            }
        });
    });
});